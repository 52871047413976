<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Markisen / Vollkassettenmarkise / Trentino <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Trentino - Flachkassette für jeden Stil
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full md:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full md:col-span-1">
          <h3
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Vollkassettenmarkise für große Terrassen und Freisitze.
          </h3>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Mit den Modellvarianten Typ Eckig und Typ Rund bietet die
            Vollkassettenmarkise Trentino Gestaltungsvielfalt für großflächige
            Terrassen und Balkone: Als Typ Eckig mit gradlinig- kantigem
            Ausfallprofil passt die Trentino perfekt zur modern-geometrischen
            Architektur, während das Design der Trentino Rund passend zu
            klassischer Architektur entwickelt wurde.
          </p>
        </div>
      </div>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Typen">
            <div class="text-lg">
              <span class="font-bold w-full"
                >Erhältlich in verschiedene Produktvarianten</span
              >
              <br />
              <ul class="flex flex-col sm:flex-row">
                <li class="px-2 flex flex-col w-2/3">
                  <div class="w-1/3">
                    <img
                      src="../assets/img/lewens/trentino/lew-trentino-eckig-kappe-seite-xl.webp"
                      alt="trentino typ eckig"
                    />
                  </div>
                  <div class="lg:pl-16">Trentino Typ Eckig</div>
                </li>
                <li class="px-2 flex flex-col w-2/3">
                  <div class="w-1/3">
                    <img
                      src="../assets/img/lewens/trentino/lew-trentino-rund-kappe-seite-xl.webp"
                      alt="trentino typ rund"
                    />
                  </div>
                  <div class="lg:pl-16">Trentino Typ Rund</div>
                </li>
              </ul>
            </div>
          </tab>
          <tab title="Maßen">
            <p class="text-lg">
              <span class="font-bold">Breite:</span> <br />
              max. 700 cm - 1-teilig (Einzelfeld)<br />
              max. 1.950 cm - 2-/3-teilig (mit Schlitzabdeckung) <br />
              <span class="font-bold">Ausfall/Tiefe:</span> <br />
              max. 400 cm <br />
              <span class="font-bold">Antrieb:</span> <br />
              Motorantrieb <br />
              Kurbelantrieb (Option)<br />
              <span class="font-bold">Volant:</span> <br />
              Volant möglich (Option) <br />
              <span class="font-bold">Markisentücher:</span> <br />
              Marken-Acryl <br />
              Marken-Polyester (Option)
            </p>
          </tab>
          <tab title="Zertifizierung">
            <div class="text-lg">
              <span class="font-bold">Zertifizierung:</span> <br />
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <div>
                    <img
                      src="../assets/madeingermany.webp"
                      alt="made in germany"
                    />
                  </div>
                  <div class="w-full pl-1">
                    Hergestellt und montiert in Deutschland
                  </div>
                </div>
                <div class="flex flex-row">
                  <div><img src="../assets/ce-norm.webp" alt="ce-norm" /></div>
                  <div class="w-full pl-1">
                    Qualität und Technik nach CE-Norm
                  </div>
                </div>
                <div class="flex flex-row">
                  <div>
                    <img src="../assets/tuev.webp" alt="tuev geprueft" />
                  </div>
                  <div class="w-full pl-1">TÜV-geprüfte Sicherheit</div>
                </div>
              </div>
            </div>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/lewens/trentino/lew-trentino-rund-ganz-haus-uli.webp"),
          alt: "vollkassettenmarkise lewens trentino blau",
        },
        {
          image: require("@/assets/img/lewens/trentino/trentinoeckig-terrasse-b1.webp"),
          alt: "vollkassettenmarkise lewens trentino rot",
        },
        {
          image: require("@/assets/img/lewens/trentino/modelle-web-rd-6.webp"),
          alt: "vollkassettenmarkise lewens trentino modell ansicht",
        },
        {
          image: require("@/assets/img/lewens/trentino/lew-trentino-eckig-kappe-seite-xl.webp"),
          alt: "trentino typ eckig",
        },
        {
          image: require("@/assets/img/lewens/trentino/lew-trentino-rund-kappe-seite-xl.webp"),
          alt: "trentino typ rund",
        },
        {
          image: require("@/assets/img/lewens/trentino/lew-vollkassetten-detail-armmittelgelenk2-xl-1.webp"),
          alt: "vollkassettenmarkise lewens trentino armansatz",
        },
      ],
    };
  },
};
</script>
